const hash_state = {
  temp_hash: false,
};

const hash = (state = hash_state, action) => {
  switch (action.type) {
    case 'PUT_HASH':
      return {
        ...state,
        [action.key]: action.value,
      };
    case 'PUT_TEMP_HASH':
      return {
        ...state,
        temp_hash: {
          ...state.temp_hash,
          [action.key]: {
            data: action.value,
            type: action.hash,
          },
        },
      };
    default:
      return state;
  }
};

export default hash;
