import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./style.module.css";

class LoadingScreen extends Component {
	render() {
		return (
			<div
				className={`${styles.wp_pop} ${styles[this.props.type]}`}
				style={{
					backgroundColor: `${this.props.backgroundColor}`
				}}
			>
				<div className={styles.wp_content}>
					<p className={styles.wp_tips}>{this.props.text}</p>
				</div>
			</div>
		);
	}
}

LoadingScreen.defaultProps = {
	text: "Loading...",
	backgroundColor: "rgba(9, 18, 34, 1)"
};

LoadingScreen.propTypes = {
	text: PropTypes.string,
	type: PropTypes.string,
	backgroundColor: function (props, propName, componentName) {
		if (props[propName].indexOf("rgb") < 0) {
			if (props[propName].indexOf("rgba") < 0) {
				if (props[propName].indexOf("#") < 0) {
					return new Error(
						"Invalid prop `" +
							propName +
							"` supplied to" +
							" `" +
							componentName +
							"`. Only accept RGB, RGBA or Hex code."
					);
				}
			}
		}
	}
};

export default LoadingScreen;
