const auth_state = {
  param: false,
  callback: false,
  url_code: false,
};

const auth = (state = auth_state, action) => {
  switch (action.type) {
    case 'SET_PARAM':
      return { ...state, param: action.data };
    case 'SET_CALLBACK':
      return { ...state, callback: action.data };
    case 'SET_URL_CODE':
      return { ...state, url_code: action.data };
    default:
      return state;
  }
};

export default auth;
